<template>
  <div>
    <el-dialog title="电子发票" :visible.sync="dialogVisible" width="34%">
      <el-form ref="formref" :model="form" label-width="auto">
        <el-form-item
          v-show="form.invoiceKind"
          label="发票种类"
          prop="purchaserId"
        >
          <span>{{
            form.invoiceKind == 'paper' ? '纸质发票' : '电子发票'
          }}</span>
        </el-form-item>
        <el-form-item
          v-show="form.invoiceTitleType"
          label="抬头类型"
          prop="purchaserId"
        >
          <span>{{ f_individual(form.invoiceTitleType) }}</span>
        </el-form-item>
        <el-form-item
          v-show="form.invoiceType"
          label="发票类型"
          prop="purchaserId"
        >
          <span>{{
            form.invoiceType == 'tax_invoice'
              ? '增值税普通发票'
              : '增值税专用发票'
          }}</span>
        </el-form-item>
        <el-form-item
          v-show="form.invoiceTitle"
          label="发票抬头"
          prop="invoiceType"
        >
          <span>{{
            form.invoiceTitle
          }}</span>
        </el-form-item>
        <el-form-item
          v-show="form.purchaserId"
          label="纳税人识别号"
          prop="purchaserId"
        >
          <span>{{ form.purchaserId }}</span>
        </el-form-item>
        <el-form-item
          v-show="form.purchaserBankName"
          label="基本开户银行"
          prop="purchaserBankName"
        >
          <!-- <el-input v-model="form.purchaserBankName"></el-input> -->
          <span>{{ form.purchaserBankName }}</span>
        </el-form-item>
        <el-form-item
          v-show="form.purchaserBankAccount"
          label="基本开户账号"
          prop="purchaserBankAccount"
        >
          <!-- <el-input v-model="form.purchaserBankAccount"></el-input> -->
          <span>{{ form.purchaserBankAccount }}</span>
        </el-form-item>
        <el-form-item
          v-show="form.purchaserAddress"
          label="企业注册地址"
          prop="purchaserAddress"
        >
          {{ form.purchaserAddress }}
        </el-form-item>
        <el-form-item
          v-show="form.purchaserAddress"
          label="企业注册地址"
          prop="purchaserAddress"
        >
          {{ form.purchaserAddress }}
        </el-form-item>
        <el-form-item
          v-show="form.email && form.invoiceKind != 'paper'"
          label="电子发票接收邮箱"
          prop="email"
        >
          <span>{{ form.email }}</span>
        </el-form-item>
        <el-form-item
          v-show="form.expressReceiver"
          label="联系人姓名"
          prop="expressReceiver"
        >
          {{ form.expressReceiver }}
        </el-form-item>
        <el-form-item
          v-show="form.expressPhone"
          label="联系电话"
          prop="expressPhone"
        >
          {{ form.expressPhone }}
        </el-form-item>
        <el-form-item
          v-show="form.expressAddress"
          label="联系地址"
          prop="expressAddress"
        >
          {{ form.expressAddress }}
        </el-form-item>
        <!-- <el-form-item
          :label="form.invoiceKind == 'paper' ? '物流单号' : '电子发票URL'"
          prop="invoiceLocation"
        >
          <el-input v-model="form.invoiceLocation" />
        </el-form-item> -->
      </el-form>
      <span v-show="status" slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSubmit">确认开票</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      form: {
        purchaserId: '',
        invoiceLocation: ''
      },
      status: false
    }
  },
  watch: {
    form: {
      handler(val, oldVal) {
        // console.log(val)
      },
      deep: true // true 深度监听
    }
  },
  methods: {
    async onSubmit() {
      const { data: res } = await this.$http.post(
        '/admin/UserOrder/outInvoice',
        {
          invoiceLocation: this.form.invoiceLocation,
          id: this.form.id
        }
      )
      if (res.errorCode == 200) {
        this.$message.success(res.message)
        this.dialogVisible = false
        this.$emit('getlist')
      }
    },
    f_individual(val) {
      if (val == 'individual') {
        return '个人'
      } else if (val == 'enterprise') {
        return '企业'
      } else if (val == 'organization') {
        return '组织'
      }
    }
  }
}
</script>

<style lang="less" scoped></style>
