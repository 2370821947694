<template>
  <div>
    <el-card>
      <nav class="out">
        <nav class="input">
          <el-input v-model="form_2.keyword" size="medium" placeholder="姓名，订单号" />
        </nav>
        <label for="" style="font-size: 14px; float: left; transform: translateY(9px)">创建时间：</label>
        <nav class="input" style="width: 250px;">
          <el-date-picker
              v-model="form_2.createTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              style="width: 100%"
              size="medium"
          />
        </nav>
        <nav class="input" style="width: auto">
          <el-button size="small" @click="reset">重置</el-button>
          <el-button type="primary" size="small" @click="subimt">{{ "搜索" }}</el-button>
        </nav>

      </nav>
    </el-card>

    <el-card style="margin-top: 10px">
      <el-table
          :data="list"
          style="width: 100%"
          border
          stripe
          :header-cell-style="rowClass"
          @sort-change="sortChange"
      >
        <el-table-column prop="id" label="ID" />
        <el-table-column prop="realName" label="姓名">
          <template slot-scope="{ row }">
            <router-link :to="{ path: '/user_list', query: { userId: row.userId } }">
              {{ row.realName || row.nickname }}
            </router-link>
          </template>
        </el-table-column>
        <el-table-column prop="sourceType" label="订单类型"> </el-table-column>
        <el-table-column prop="sourceName" label="订单内容" />
        <el-table-column prop="payType" label="支付类型" />
        <el-table-column prop="tradeNo" label="订单号">
          <template slot-scope="{ row }">
            <p>本站：{{ row.tradeNo }}</p>
            <p>支付平台：{{ row.outTradeNo }}</p>
          </template>
        </el-table-column>

        <!-- <el-table-column prop="endTimeType" label="合作截至时间">
  <template slot-scope="{ row }">
    <p>{{ row.endTimeType ? '长期有效' : row.endTime }}</p>
  </template>
</el-table-column> -->
        <el-table-column prop="terminal" label="付款金额">
          <template slot-scope="{ row }">
            <p>用户订单金额：￥{{ row.orderAmount }}</p>
            <p>用户实际支付金额：￥{{ row.payAmount }}</p>
            <p v-if="+row.couponAmount">优惠券优惠金额￥{{ row.couponAmount }}</p>
          </template>
        </el-table-column>

        <el-table-column prop="terminal" label="付款终端">
          <template slot-scope="{ row }">
            {{ row.terminal }}
            <p v-if="row.terminalPhone">({{ row.terminalPhone }})</p>
          </template>
        </el-table-column>
        <el-table-column prop="terminal" label="手续费">
          <template slot-scope="{ row }">
            ¥{{ row.payAmount - row.refundAmount}}
            <p v-if="row.refundTicketCharges">手续费{{ row.refundTicketCharges }}%</p>
          </template>
        </el-table-column>
        <el-table-column prop="refundAmount" label="实际退款金额">
          <template slot-scope="{ row }">
            ¥{{ row.refundAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="refundReason" label="退款原因">
          <template slot-scope="{ row }">
            {{ row.refundReason }}
          </template>
        </el-table-column>
        <el-table-column prop="refundReason" label="状态">
          <template slot-scope="{ row }">
            <el-tag type="warning" size="small" class="box">{{
                trade_status(row.tradeStatus)
              }}</el-tag>
            <div v-if="row.refundFailReason">不通过原因：{{row.refundFailReason}}</div>
          </template>
        </el-table-column>

        <el-table-column prop="sendTime" label="订单创建时间">
          <template slot-scope="{ row }">
            {{ row.createTime }}
<!--            <p>创建时间：{{ row.createTime }}</p>-->
<!--            <p>支付时间：{{ row.payTime }}</p>-->
          </template>
        </el-table-column>
        <el-table-column  label="操作">
          <template slot-scope="{ row }">
            <el-button  type="text"
                        icon="el-icon-setting"
                        size="mini" @click="handleOpen('审核通过',row)">审核通过</el-button>
            <el-button type="text"
                       icon="el-icon-setting"
                       size="mini"
                       class="red"
                       @click="handleOpen('审核不通过',row)">审核不通过</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible">
        <el-form :model="refundForm" :rules="rules" ref="refundForm">
          <el-form-item v-if="dialogTitle == '审核不通过'" prop="refundFailReason" label="审核不通过原因" label-width="120px">
            <el-input v-model="refundForm.refundFailReason" ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="cancel()">取 消</el-button>
          <el-button type="primary" @click="handleSubmit">确 定</el-button>
        </div>
      </el-dialog>
      <el-pagination
          :current-page="currentPage"
          background
          :page-sizes="[3, 5, 10, 15]"
          :page-size="pagesize"
          layout="total, prev, pager, next"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
      />
    </el-card>
    <invoice ref="invoice" @getlist="getlist" />
  </div>
</template>

<script>
import dialogForm from "@/components/newForm/dialogForm.vue";

var that;
import show from "../../components/countDowns/show.vue";
// import mixins from '../../mixin/getdiction'
import invoice from "./invoice.vue";
export default {
  components: {
    Show: show,
    invoice,
  },
  data() {
    return {
      currentOrder:{},
      dialogTitle:'',
      // 退款审核表单
      refundForm:{
        refundFailReason:''
      },
      rules:{
        refundFailReason:  [
      { required: true, message: '请输入退款原因', trigger: 'blur' },
    ],
      },
      // 订单审核对话框
      dialogFormVisible:false,
      title: "",
      year: "",
      loadingExcel: false,
      publishStatus: "",
      reportArea: "",
      reportBusiness: "",
      order: "",
      confing: "",
      btn: true,
      action: {
        action: "enterprise",
      },
      dioaddkuai: false,
      currentPage: 1,
      pagesize: 10,
      list: [],
      pai: [],
      paiform: {},
      value: "",
      dialogVisible: false,
      dialogVisiblexui: false,
      baseurl: this.$store.state.env.cos,
      yu: "/" + this.$store.state.env.domain,
      total: 0,
      proof: false,
      form: {
        title: "",
        source: "",
        picture: "",
        content: "",
        desc: "",
        areaCode: "",
        banner: "",
        viewType: "",
        businessId: "",
      },
      form_1: {
        id: "",
        title: "",
        source: "",
        picture: "",
        content: "",
        desc: "",
        areaCode: "",
        banner: "",
        viewType: "",
        businessId: "",
      },
      form_2: {
        page: 1,
        pageSize: 10,
        keyword: "",
        sourceType: "",
        tradeStatus: "",
        createTime: [],
        invoiceStatus: "",
        // order: ''
      },
      form_3: {
        phone: this.$cookies.get("phone"),
      },
      s_form: {
        page: 1,
        pageSize: 10,
        keyword: "",
        sourceType: "",
        tradeStatus: "",
        createTime: [],
        invoiceStatus: "",
      },
      imageUrl: "",
      imageUrl_1: "",
      header: {
        Token: sessionStorage.getItem("token"),
      },
      otherDetail: {},
      form_show: {},
      area: [],
      orderType: [],
      config: null,
    };
  },
  computed: {
    /**
     *状态码
     * @param
     */
    trade_status(val){
      return (val) => {
        var key;
        switch (val) {
          case 3:
            key = "待审核";
            break;
          case 4:
            key = "待审核";
            break;
          case 6:
            key = "审核不通过";
            break;
          case 7:
            key = "审核不通过";
            break;
        }
        return key;
      };
    },
    show_status() {
      return (val) => {
        var key;
        switch (val) {
          case 0:
            key = "等待支付";
            break;
          case 1:
            key = "支付成功";
            break;
          case 2:
            key = "未付款交易超时关闭";
            break;
        }
        return key;
      };
    },
  },
  // mixins: [mixins],
  beforeCreate() {
    that = this;
  },
  updated() {
    //   console.log(this.form);
  },

  created() {
    // this.getconfig()
    // this.getconfig('user')
    this.form_2.invoiceStatus = this.s_form.invoiceStatus = +this.$route.query.invoiceStatus || "";
    this.getlist();
    sessionStorage.setItem("action", "cooperation");
    if (!this.config) {
      this.getTheConfiguration();
    }
  },
  methods: {
    /**
     *
     * 取消
     */
    cancel(){
      this.resetRefundForm()
      this.$message({
        type: 'info',
        message: '已取消'
      });
    },
    /**
     * 对话框
     * @param title
     * @param data
     */
    handleOpen(title,data){
      this.currentOrder=data
      if(title =="审核通过"){
        this.$confirm('请确认审核通过?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          this.$http
              .get("/admin/UserOrder/refundCheckStep1", {
                params: {
                  status:1,
                  failReason:"",
                  orderId:this.currentOrder.id
                },
              })
              .then(({ data: res }) => {
                if(res.errorCode==200){
                  this.$message({
                    message: '操作成功',
                    type: 'success'
                  });
                  this.resetRefundForm()
                  this.getlist()
                }
              });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
      }else {
        this.dialogTitle=title
        this.refundForm.refundFailReason=data.refundFailReason
        this.dialogFormVisible=true
      }


    },
    resetRefundForm(){
      this.refundForm={
        refundFailReason:''
      }
      this.dialogFormVisible=false
    },
    /**
     * 订单退款审核
     */
    handleSubmit(){
      this.$refs['refundForm'].validate((valid)=>{
        if (valid) {
          console.log(this.refundForm)
          this.$http
              .get("/admin/UserOrder/refundCheckStep1", {
                params: {
                  status:2,
                  failReason:this.refundForm.refundFailReason,
                  orderId:this.currentOrder.id
                },
              })
              .then(({ data: res }) => {
                if(res.errorCode==200){
                  this.$message({
                    message: '操作成功',
                    type: 'success'
                  });
                  this.resetRefundForm()
                  this.getlist()
                }
              });
        } else {
          return false;
        }
      })
    },
    reset() {
      this.form_2 = {
        page: 1,
        pageSize: 10,
        keyword: "",
        createTime: [],
      };
      this.s_form = {
        ...this.form_2,
      };
      this.getlist();
    },
    subimt() {
      this.currentPage = 1;
      this.form_2.page = 1;
      this.s_form = {
        ...this.form_2,
      };
      this.getlist();
    },
    show_type(val) {
      if (val == 1) {
        return "提供服务";
      } else {
        return "需要资源";
      }
    },
    async exportExcel() {
      this.loadingExcel = true;
      let { data: res } = await this.$http.get("/admin/UserOrder/export", {
        params: {
          ...this.form_2,
          createTime: this.form_2.createTime.join("~"),
          sourceType: this.form_2.sourceType == -1 ? "" : this.form_2.sourceType,
        },
      });
      this.loadingExcel = false;
      if (res.errorCode == 200) {
        this.$message.success(res.message);
        location.href = res.data.url;
      }
    },
    check() {
      console.log(1);
    },
    async Oninvoice(row, val) {
      if (this.$store.state.btn_if.indexOf("order_invoice") == -1) {
        this.$message.error("你没有开票权限★");
        return;
      }
      console.log(row);
      if (val == 0) {
        this.$message.error("你还没有申请");
        return;
      }
      if (row.invoiceInfo.length > 0) {
        var info = JSON.parse(row.invoiceInfo);
        if (!info.invoiceLocation) {
          info = {
            ...info,
            invoiceLocation: row.invoiceLocation,
          };
        }
        this.$refs.invoice.form = {
          ...info,
          id: row.id,
        };
        this.$refs.invoice.status = val != 2;
        this.$refs.invoice.dialogVisible = true;
      }
    },
    information(row) {
      this.showinfo = true;
      // this.form_show = row
      this.$http
          .get("/admin/UserSpeaker/getById", {
            params: {
              id: row.id,
            },
          })
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.form_show = res.data;
            }
          });
    },
    show_invoice(row) {
      if (row == 0) {
        return "未申请";
      } else if (row == 1) {
        return "发票申请中";
      } else if (row == 2) {
        return "已开票";
      }
    },
    sortChange(c) {
      console.log(c);
      if (c.order) {
        var s = "activity_" + c.prop;
        if (c.prop == "orderNum") {
          s = "report_order_num";
        } else if (c.prop == "views") {
          s = "report_views";
        } else {
          s = "report_" + c.prop;
        }
        var p = c.order.slice(0, c.order.length - 6);
        this.order = s + " " + p;
      } else {
        this.order = "";
      }

      this.getlist();
    },

    addkuai() {
      this.addkuai = false;
    },
    getea() {
      this.$http.get("/admin/ReportCategory/getList").then(({ data: res }) => {
        if (res.errorCode == 200) {
          this.area = res.data;
        }
      });
    },
    rowClass() {
      return "background:#F5F7FA;";
    },
    handleSizeChange(e) {
      this.pagesize = e;
      console.log(e);
    },
    handleCurrentChange(e) {
      // this.getlist()

      this.s_form.page = e;

      this.getlist(this.s_form);
    },
    getlist(val) {
      var form = val || this.form_2;
      console.log(form)
      this.$http
          .get("/admin/UserOrder/getRefundListStep1", {
            params: {
              page:form.page,
              pageSize:form.pageSize,
              keyword:form.keyword,
              createTime: form.createTime.join("~"),
            },
          })
          .then(({ data: res }) => {
            console.log(res)
            if (res.errorCode == 200) {
              let { list, ...other } = res.data;
              this.list = res.data.list;
              this.otherDetail = other;
              this.total = res.data.totalCount;
            }
          });
    },
    checkf(val) {
      this.form_3 = val;
      // console.log(this.form_3)
    },
    async beforedown() {
      const phone = this.$cookies.get("phone");
      if (!phone) {
        this.$message.error("没有绑定手机号");
        return;
      }
      if (!this.proof) {
        this.dioA = true;
      } else {
        this.download();
      }
    },
    async download() {
      if (this.btn) {
        this.btn = false;
        // const { data: res } = await this.$http.get(
        //   '/admin/Common/checkSmsCode',
        //   {
        //     params: {
        //       ...this.form_3
        //     }
        //   }
        // )
        // this.btn = true
        // // console.log(this.btn)
        // this.$forceUpdate()
        if (false) {
          // this.$message.error(res.message)
          // return
        } else {
          const { data: res } = await this.$http.get("/admin/UserSpeaker/exportExcel", {
            params: {
              ...this.form_2,
              ...this.form_3,
            },
          });
          this.proof = true;
          this.btn = true;
          if (res.errorCode == 200) {
            this.$message.success(res.message);
            location.href = res.data.url;
            this.dioA = false;
          }
        }
      }
    },
    add() {},
    handleAvatarSuccess(res, file) {
      if (res.errorCode == 200) {
        this.form.banner = res.data.url;
      }
      console.log(file);
      //   console.log(imgurlbase)
    },
    async getTheConfiguration() {
      let { data: res } = await this.$http.get("/admin/Common/getConfigDictionaries?config=order");
      this.config = res.data;
    },
    // beforeAvatarUpload(file) {
    //   const isJPG = file.type === 'image/jpeg'
    //   const isLt2M = file.size / 1024 / 1024 < 2

    //   if (!isJPG) {
    //     this.$message.error('上传头像图片只能是 JPG 格式!')
    //   }
    //   if (!isLt2M) {
    //     this.$message.error('上传头像图片大小不能超过 2MB!')
    //   }
    //   return isJPG && isLt2M
    // },
    handleAvatarSuccess_1(res, file) {
      if (res.errorCode == 200) {
        this.form_1.banner = res.data.url;
      }
      console.log(file);
      //   console.log(imgurlbase)
    },
    beforeAvatarUpload_1(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },

    addedit() {
      if (this.btn) {
        // this.form.picture = this.form.picture.slice(this.baseurl.length)

        this.$http.post("/admin/News/edit", this.form_1).then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.dialogVisiblexui = false;
            this.$message.success(res.message);
            this.getlist();
          }
          this.btn = true;
        });
      }
      this.btn = false;
    },
    edit(id) {
      // sessionStorage.setItem('flag', false)
      // this.p_a = true
      this.$router.push({
        name: "cooperation_list_add",
        params: {
          id,
        },
      });
    },
    open(id) {
      this.$confirm("此操作将永久删除该, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            this.$http
                .delete("/admin/cooperation/del", {
                  params: {
                    id: id,
                  },
                })
                .then((res) => {
                  //   console.log(res)
                  if (res.data.errorCode == 200) {
                    this.$message({
                      type: "success",
                      message: "删除成功!",
                    });
                    if (this.total % 10 == 1) {
                      this.currentPage--;
                    }
                    this.getlist();
                  }
                });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    setTop(row, val) {
      // console.log(1)
      // console.log(row, id)
      this.$http
          .post("/admin/cooperation/top", {
            id: row.id,
            top: val,
          })
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.$message.success(res.message);
              this.getlist();
            } else {
              this.$message({
                message: res.message,
                type: "error",
                customClass: "zZindex",
              });
            }
            // this.btn1 = true
          });
    },
    show(id, el) {
      this.$http
          .post("/admin/Cooperation/hide", {
            id: id,
            isHide: el,
          })
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.$message.success(res.message);
              this.getlist();
            }
          });
    },
    chagne(row) {
      this.$http
          .post("/admin/UserSpeaker/editService", {
            id: row.id,
            waId: row.waId.join(","),
          })
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.getlist();
            } else {
              this.getlist();
            }
          });
    },
  },
};
</script>

<style lang="less" scoped>
.out {
  // width: 500px;

  margin-bottom: 20px;

  .input {
    width: 37%;
    display: inline-block;
    margin-right: 30px;
  }
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.skyblue {
  color: skyblue;
}

.green {
  color: green;
}

.yellow {
  color: pink;
}

.red {
  color: red;
}

.out .input {
  width: 18%;
}

.el-card {
  position: relative;
}

.out .right {
  float: right;
}

.box {
  display: inline-block;
  margin: 5px;
}
</style>
