<template>
  <div>
    <!-- <el-card> -->
    <el-form
      ref="form"
      :model="form"
      label-width="80px"
      :inline="false"
      size="normal"
    >
      <el-form-item label="手机号(王璐)">
        <el-input v-model="form.phone" disabled />
      </el-form-item>
      <el-form-item label="验证码">
        <el-input v-model="form.smsCode">
          <Count ref="count" slot="append" @code="getcode" />
        </el-input>
      </el-form-item>
    </el-form>
    <!-- </el-card> -->
  </div>
</template>

<script>
import count from './index.vue'
export default {
  components: {
    Count: count
  },
  data() {
    return {
      form: {
        phone: '15080329619',
        smsCode: ''
      }
    }
  },
  watch: {
    'form.smsCode'(newValue, oldValue) {
      this.$emit('form', this.form)
    }
  },
  methods: {
    async getcode() {
      if (!this.form.phone) {
        this.$message({
          message: '请输入手机号',
          type: 'warning'
        })
        return
      }
      const graphicsRes = await this.$store.dispatch('checkGraphics')
      this.$refs.count.countDown()
      // 发送手机验证码

      const { ticket, randstr } = graphicsRes
      const reqData = {
        phone: this.form.phone,
        ticket,
        randStr: randstr
      }
      this.form = {
        ...this.form,
        ...reqData
      }
      const { data: res } = await this.$http.post(
        '/admin/Common/sendSmsCode',
        reqData
      )
      if (res.errorCode == 200) {
        // this.form.smsCode = res.data.code
        this.$emit('form', this.form)
      }
    }
  }
}
</script>

<style lang="less" scoped></style>
