var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-card',[_c('nav',{staticClass:"out"},[_c('nav',{staticClass:"input"},[_c('el-input',{attrs:{"size":"medium","placeholder":"姓名，订单号"},model:{value:(_vm.form_2.keyword),callback:function ($$v) {_vm.$set(_vm.form_2, "keyword", $$v)},expression:"form_2.keyword"}})],1),_c('label',{staticStyle:{"font-size":"14px","float":"left","transform":"translateY(9px)"},attrs:{"for":""}},[_vm._v("创建时间：")]),_c('nav',{staticClass:"input",staticStyle:{"width":"250px"}},[_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"type":"daterange","range-separator":"至","start-placeholder":"开始日期","end-placeholder":"结束日期","format":"yyyy-MM-dd","value-format":"yyyy-MM-dd","size":"medium"},model:{value:(_vm.form_2.createTime),callback:function ($$v) {_vm.$set(_vm.form_2, "createTime", $$v)},expression:"form_2.createTime"}})],1),_c('nav',{staticClass:"input",staticStyle:{"width":"auto"}},[_c('el-button',{attrs:{"size":"small"},on:{"click":_vm.reset}},[_vm._v("重置")]),_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.subimt}},[_vm._v(_vm._s("搜索"))])],1)])]),_c('el-card',{staticStyle:{"margin-top":"10px"}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.list,"border":"","stripe":"","header-cell-style":_vm.rowClass},on:{"sort-change":_vm.sortChange}},[_c('el-table-column',{attrs:{"prop":"id","label":"ID"}}),_c('el-table-column',{attrs:{"prop":"realName","label":"姓名"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('router-link',{attrs:{"to":{ path: '/user_list', query: { userId: row.userId } }}},[_vm._v(" "+_vm._s(row.realName || row.nickname)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"sourceType","label":"订单类型"}}),_c('el-table-column',{attrs:{"prop":"sourceName","label":"订单内容"}}),_c('el-table-column',{attrs:{"prop":"payType","label":"支付类型"}}),_c('el-table-column',{attrs:{"prop":"tradeNo","label":"订单号"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',[_vm._v("本站："+_vm._s(row.tradeNo))]),_c('p',[_vm._v("支付平台："+_vm._s(row.outTradeNo))])]}}])}),_c('el-table-column',{attrs:{"prop":"terminal","label":"付款金额"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',[_vm._v("用户订单金额：￥"+_vm._s(row.orderAmount))]),_c('p',[_vm._v("用户实际支付金额：￥"+_vm._s(row.payAmount))]),(+row.couponAmount)?_c('p',[_vm._v("优惠券优惠金额￥"+_vm._s(row.couponAmount))]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"terminal","label":"付款终端"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.terminal)+" "),(row.terminalPhone)?_c('p',[_vm._v("("+_vm._s(row.terminalPhone)+")")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"terminal","label":"手续费"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" ¥"+_vm._s(row.payAmount - row.refundAmount)+" "),(row.refundTicketCharges)?_c('p',[_vm._v("手续费"+_vm._s(row.refundTicketCharges)+"%")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"refundAmount","label":"实际退款金额"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" ¥"+_vm._s(row.refundAmount)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"refundReason","label":"退款原因"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.refundReason)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"refundReason","label":"状态"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tag',{staticClass:"box",attrs:{"type":"warning","size":"small"}},[_vm._v(_vm._s(_vm.trade_status(row.tradeStatus)))]),(row.refundFailReason)?_c('div',[_vm._v("不通过原因："+_vm._s(row.refundFailReason))]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"sendTime","label":"订单创建时间"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.createTime)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text","icon":"el-icon-setting","size":"mini"},on:{"click":function($event){return _vm.handleOpen('审核通过',row)}}},[_vm._v("审核通过")]),_c('el-button',{staticClass:"red",attrs:{"type":"text","icon":"el-icon-setting","size":"mini"},on:{"click":function($event){return _vm.handleOpen('审核不通过',row)}}},[_vm._v("审核不通过")])]}}])})],1),_c('el-dialog',{attrs:{"title":_vm.dialogTitle,"visible":_vm.dialogFormVisible},on:{"update:visible":function($event){_vm.dialogFormVisible=$event}}},[_c('el-form',{ref:"refundForm",attrs:{"model":_vm.refundForm,"rules":_vm.rules}},[(_vm.dialogTitle == '审核不通过')?_c('el-form-item',{attrs:{"prop":"refundFailReason","label":"审核不通过原因","label-width":"120px"}},[_c('el-input',{model:{value:(_vm.refundForm.refundFailReason),callback:function ($$v) {_vm.$set(_vm.refundForm, "refundFailReason", $$v)},expression:"refundForm.refundFailReason"}})],1):_vm._e()],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){return _vm.cancel()}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v("确 定")])],1)],1),_c('el-pagination',{attrs:{"current-page":_vm.currentPage,"background":"","page-sizes":[3, 5, 10, 15],"page-size":_vm.pagesize,"layout":"total, prev, pager, next","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1),_c('invoice',{ref:"invoice",on:{"getlist":_vm.getlist}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }